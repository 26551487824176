.menu-list--icon-icon {
    img {
        max-width: 50px;
        min-width: 40px;
        width: 100%;
    }
}

.menu-list--icon-description {
    h5 {
        font-size: 18px;
        color: black;
    }

    h6 {
        font-size: 14px;
        color: #666666;
    }
}