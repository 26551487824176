.country-page-title--image {
    width: 50px;
    height: 50px;
}

.country-page-title--container {
    padding-top: 10px;
    border-top: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding-bottom: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
}