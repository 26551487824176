.navbar { 
    background-color: #E8E8E8;
    .navbar--item {
        &, a {
            font-size: 1.5rem;
            margin-left: 20px; 
            color: #1C3664 
        }
    }  
}
